import React from "react";
import {OverlayTrigger, Tooltip} from "react-bootstrap";

class SubBadge extends React.PureComponent {
    constructor(props) {
        super(props);
        this.key = this.props.key;
    }

    render() {
        return (
            <OverlayTrigger
                key={`badge-top-${new Date().valueOf()}`}
                placement="top"
                overlay={
                    <Tooltip id={`tooltip-badge-${new Date().valueOf()}`}>
                        <span className="tooltip-badge chat-badge" style={this.props.tooltipBadge}/>
                        <div>{this.props.badgeName}</div>
                    </Tooltip>
                }>
                <span key={this.key} className="chat-badge" style={this.props.badge}/>
            </OverlayTrigger>
        );
    }
}

export default SubBadge;
