import React from "react";
import {Accordion, Button, Col, Container, FormCheck, FormControl, Modal, Row} from "react-bootstrap";
import Header from "./Header";
import "./Main.scss";
import List from "./List";
import StreamInput from "./StreamInput";
import Footer from "./Footer";
import UserCard from "./UserCard";
let wrapperSet = false;

class Main extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            subOnly: true,
            nonSubOnly: true,
            spamCondenser: false,
            showFTCModal: false,
            showSpotlightModal: false,
            spam: false,
            modal: '',
            loaded: this.props.loaded,
            streamerInput: '',
            cardChatter: '',
            userCard : null
        };
    }

    checkHandler(obj) {
        const newState = {};
        const element = document.getElementById(obj)
        newState[obj] = element.checked;
        this.setState(newState);
    }

    showFTCModal() {
        this.setState({showFTCModal: true});
    }

    handleFTCClose() {
        this.setState({showFTCModal: false});
    }

    showSpotlightModal() {
        this.setState({showSpotlightModal: true});
    }

    handleSpotlightClose() {
        this.setState({showSpotlightModal: false});
    }

    addSpotlight() {
        const input = document.getElementById("spotlight");
        if (!input.value) return;
        this.props.spotlightHandler(input.value.toLowerCase().trim());
        input.value = "";

    }

    keyPressHandler(e) {
        if (e.key.toLowerCase() === "enter") {
            this.addSpotlight();
        }
    }

    changeHandler(e) {
        if (e.length) {
            this.setState({streamerInput: e[0].label.trim()});
        }
    }

    mainPressHandler(trigger, selection) {
        if (selection) {
            this.props.submissionHandler(true);
            this.setState({streamerInput: selection});
            this.mainClickHandler(selection);
            return selection;
        }
    }

    mainClickHandler(input) {
        let value = document.getElementById("streamer-input").value;
        if (typeof input === "string") {
            value = input;
        }
        this.props.streamerClickHandler(value);
        this.setState({loaded: true});
    }

    closeUserCard() {
        this.setState({userCard: ''});
    }

    componentDidMount() {
        this.setListeners();
    }

    setListeners() {
        let wrapper = document.getElementById("top-chat-list-wrapper");
        if (wrapper) {
            wrapperSet = true;
            wrapper.addEventListener("click", (e) => {
                if (e.target.classList.contains("tc-reply-btn")) {
                    this.props.replyHandler(e);
                    document.getElementById("tc-messenger")?.focus();
                } else if (e.target.classList.contains("username")) {
                    e.preventDefault();
                    this.setState({
                        userCard: <UserCard chatter={e.target.innerText} messageHandler={this.props.getMessages}
                                            url={e.target.getAttribute("href")}
                                            spotlightHandler={this.props.spotlightHandler}
                                            closeHandler={this.closeUserCard.bind(this)}/>
                    });
                }
            });
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (wrapperSet) return;
        this.setListeners();
    }

    showUI() {
        if (this.state.loaded) {
            return <><Header onClickHandler={this.props.streamerClickHandler} streamer={this.props.streamer}
                             changeHandler={this.changeHandler.bind(this)}
                             mainPressHandler={this.mainPressHandler.bind(this)}
                             mainClickHandler={this.mainClickHandler.bind(this)}
                             user={this.props.user}
                             stream={this.props.streamInfo} isLive={this.props.isLive}/><Row id="top-chat-list-wrapper"
                                                                                             className={(this.state.loaded ? '' : "d-none")}>
                <Col md={6} sm={12} className="pr-0">
                    <Accordion>
                        <Accordion.Item eventKey="0">
                            <Accordion.Header>Options</Accordion.Header>
                            <Accordion.Body>
                                <div
                                    className={"tc-header-chat-options " +(this.props.streamer.displayName !== "No Connection" && this.props.streamer.displayName !== "No User Found" ? "d-inline-flex" : "d-none")}>
                                    <FormControl id="spotlight" size="sm" placeholder="Enter user to spotlight"
                                                 onKeyDown={this.keyPressHandler.bind(this)}/>
                                    <Button variant="outline-primary" className="mx-1" size="sm"
                                            onClick={this.addSpotlight.bind(this)}>&#128269;</Button>
                                    <Button variant="outline-success" className="mx-1" size="sm"
                                            onClick={this.showSpotlightModal.bind(this)}>Edit</Button>
                                    <FormCheck defaultChecked={true} id="bots" onChange={this.props.botsHandler.bind(this)}
                                               className="mx-1" label="Bots"/>
                                    <FormCheck defaultChecked={true} id="emoteOnly" onChange={this.props.emoteOnlyHandler.bind(this)}
                                               className="mx-1" label="Emote-Only Messages"/>
                                    <FormCheck defaultChecked={false} id="commands" onChange={this.props.commandHandler.bind(this)}
                                               className="mx-1" label="!command Messages"/>
                                </div>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>

                    <List title="TOP CHAT" listId="top-chat" className={this.state.spam ? 'tc-spam-condenser' : ''}
                          msgs={this.props.msgs}/>
                </Col>
                <Col md={6} sm={12} className="pl-0">
                    <Accordion>
                        <Accordion.Item eventKey="0">
                            <Accordion.Header>Options</Accordion.Header>
                            <Accordion.Body>
                                <div
                                    className={this.props.streamer.displayName !== "No Connection" && this.props.streamer.displayName !== "No User Found" ? "d-inline-flex" : "d-none"}>
                                    <FormCheck defaultChecked={true} id="subOnly" onChange={this.checkHandler.bind(this, "subOnly")}
                                               className="mx-1" label="Subs"/>
                                    <FormCheck defaultChecked={true} id="nonSubOnly"
                                               onChange={this.checkHandler.bind(this, "nonSubOnly")} className="mx-1"
                                               label="Non-Subs"/>
                                    <FormCheck defaultChecked={false} id="spam" onChange={this.checkHandler.bind(this, "spam")}
                                               className="mx-1" label="Spam Condenser"/>

                                    <Button variant="outline-primary" className="mx-1" size="sm"
                                            onClick={this.showFTCModal.bind(this)}>First Time Chats</Button>
                                </div>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                    <Modal scrollable={true} dialogClassName="tc-modal" show={this.state.showFTCModal}
                           onHide={this.handleFTCClose.bind(this)}>
                        <Modal.Header closeButton>
                            <Modal.Title>First Time Chatters</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <List title=" " listId="first-time-chatters" msgs={this.props.firstTimeChatters}/>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="outline-danger" className="mr-auto" onClick={this.props.clearLog}>
                                Empty Messages
                            </Button>
                            <Button variant="outline-secondary" onClick={this.handleFTCClose.bind(this)}>
                                Close
                            </Button>
                        </Modal.Footer>
                    </Modal><Modal scrollable={true} dialogClassName="tc-modal" show={this.state.showSpotlightModal}
                                   onHide={this.handleSpotlightClose.bind(this)}>
                    <Modal.Header closeButton>
                        <Modal.Title>Spotlight Users</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {Array.from(this.props.spotlights).map(spotlight => <div className="my-2"
                                                                                 key={`${spotlight[0]}-spotlight-${new Date().valueOf()}`}>
                            <Button size="sm" className="mx-2" variant="outline-danger"
                                    onClick={this.props.removeSpotlight.bind(this, spotlight[0])}>&times;</Button>
                            <span>{spotlight[0]}</span></div>)}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="outline-secondary" onClick={this.handleSpotlightClose.bind(this)}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
                    <List title="STREAM CHAT" listId="all-chat" className={this.state.spam ? 'tc-spam-condenser' : ''}
                          msgs={this.props.allChats} filters={this.state}/>
                </Col>
            </Row></>
        }
        return <div className="tc-welcome-display">
            <h2>Welcome to TopChat!</h2>
            <h4>Now with chatting!</h4>
            <p>Enter a streamer in the field below to join their chat</p>
            <StreamInput onChange={this.changeHandler.bind(this)} onKeyDown={this.mainPressHandler.bind(this)}
                         user={this.props.user} onClick={this.mainClickHandler.bind(this)}/>
            <h4 className="mt-4">Features:</h4>
            <ul>
                <li>See "elevated" chatter messages<br/>
                    <small>Elevated chatters: Mods, VIPs, Twitch Staff, Broadcaster, and Followed (Users that you follow)</small>

                </li>
                <li>
                    Spotlight specific chatters to add their messages into the top chat list
                </li>
                <li>
                    See first time chatter messages with an <span
                    style={{borderLeft: "8px darkmagenta solid"}}>indicator</span>
                </li>
                <li>
                    Click on a chatter's name to open up their user card
                </li>
                <li>
                    Click on the streamer's profile picture in the top right to open their stream
                </li>
                <li>
                    Filter out sub/non-sub/bot* messages, condense chat spam to see more messages
                    <br/>
                    <small>Bot filtering doesn't cover all bots yet, hopefully will be in the future</small>
                </li>
            </ul>
            <div>
                Join the <a href="https://discord.gg/8JCcW4nQzR" rel="noreferrer" target="_blank">Discord</a> or send a DM on <a
                href="https://twitter.com/topchattwitch" rel="noreferrer" target="_blank">Twitter</a> for any suggestions, bug reports,
                or for updates!
                <div></div>
            </div>
        </div>
    }


    render() {
        return (
            <Container fluid>
                {this.showUI()}
                {this.state.userCard}
                <div id="tc-footer">
                    <Footer currentStreamer={this.props.streamer}
                            typeaheadEmotes={this.props.emotes}
                            getEmoteUrl={this.props.getEmoteUrl}
                            user={this.props.user}
                            fromFetched={this.props.fromFetched}
                            clearReply={this.props.clearReply}
                            reply={this.props.reply} />
                </div>
            </Container>
        );

    }
}

export default Main;
