import React from "react";
import BadgeList from "./BadgeList";
import "./Chat.scss"
import Emote from "./Messages/Emote";
import Text from "./Messages/Text";
import {Badge, Button} from "react-bootstrap";

class Chat extends React.PureComponent {
    constructor(props) {
        super(props);
        this.index = this.props.key;
        this.message = this.props.msg;
        if (this.props.reply) {
            this.message = this.props.reply.reply;
        }
        this.user = this.props.user;
        this.userCardUrl = this.props.userCardUrl;
        this.badges = this.props.badgeList ? this.props.badgeList : [];
        this.ftc = this.props.firstTimeChatter ? " tc-ftc" : "";
        this.mention = this.props.flags.mention ? " tc-mention" : ""
        this.issueClass = this.props.issueClass ? (" " + this.props.issueClass) : ""
        this.chatClass = `chat-line__message tw-relative p-0 list-group-item tc-msg${this.ftc}${this.mention}${this.issueClass}`;
    }

    displayMessage(msg) {
        let message = this.message;
        if (msg) {
            message = msg;
        }
        return message.map((msg, i) => {
            if (typeof msg === "string") {
                return <Text key={`${msg + i}-${new Date().valueOf()}`} msg={msg}/>;
            } else {
                return <Emote key={`${msg + i}-${new Date().valueOf()}`} emote={msg}/>;
            }
        });
    }

    showReply() {
        if (this.props.reply) {
            return <div className="tc-reply">Replying
                to {this.props.reply.originalChatter}: {this.props.reply.originalMessage}</div>;
        }
        return '';
    }

    render() {
        return (
            <li className={this.chatClass} key={this.index}>
                <Button key={this.props.messageId} title={"Reply to message"} msgid={this.props.messageId} chatter={this.user.username} className={"tc-reply-btn"}>&#10558;</Button>
                <div>{this.showReply()}
                    <Badge className="tc-timestamp">{this.props.time} </Badge>
                    <BadgeList key={`badges-list-${new Date().valueOf()}`} badges={this.badges}/>
                    <span className="username" href={this.userCardUrl} style={this.user.style}>{this.user.username}</span>
                    <span className="text-light">: {this.displayMessage()}</span></div>
            </li>
        );
    }
}

export default Chat;
