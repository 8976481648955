import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
if (document.location.pathname !== "/twitch-oauth/redirect") {

    ReactDOM.render(
        <React.StrictMode>
            <App/>
        </React.StrictMode>,
        document.getElementById('root')
    );
} else {
    ReactDOM.render(
        <React.StrictMode><span style={{color: "white"}}>Redirecting... click</span> <a href={window.location.origin}>here</a> <span style={{color: "white"}}>is redirect does not automatically happen</span>
        </React.StrictMode>,
        document.getElementById('root')
    );
    fetch("/twitch-oauth/redirect"+document.location.search).then(() =>  {
        const path = localStorage.getItem("currentPath");
        window.location.href = window.location.origin + path ? path : '';
    });
}
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
