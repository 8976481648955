import React from "react";
import ReactStopwatchM from "react-stopwatch";



class Uptime extends React.Component {
    render() {
        return (
            <ReactStopwatchM
                seconds={this.props.startTime.seconds}
                minutes={this.props.startTime.minutes}
                hours={this.props.startTime.hours}
                render={({ formatted }) => {
                    return (
                        <div>{ formatted }</div>
                    );
                }}/>
        );
    }
}

export default Uptime;
