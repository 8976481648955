import React from "react";
import TextInput from "../../autocomplete";

class StreamInput extends React.Component {

    render() {
        return <div className="d-inline-flex" id="user-input">
            <TextInput Component={"input"} className={"form-control"} autoComplete={"off"} matchAny={true} id={"streamer-input"} placeholder={"Enter Streamer"}
                       changeOnSelect={this.props.onKeyDown} onKeyDown={(e) => {
                       if (e.key.toLowerCase() === "enter") this.props.onClick(e.target.value);}
            } options={this.props.user.follows} spacer="" passThroughEnter={true} trigger={[""]} minChars={2}/>
            <button className="btn btn-primary p-1" id="enter-btn" onClick={this.props.onClick}>Join</button>
        </div>;
    }
}
export default StreamInput;
