import React from "react";
import Chat from "../Chat/Chat";
import Event from "../Chat/Event";
import Pauser from "./Pauser";
import "./List.scss";

class List extends React.Component {
    constructor(props) {
        super(props);
        this.needsScroll = false;
        this.list = document.getElementById(this.props.listId);
        this.lastScrollTop = 0;
        this.scrolledUp = false;
        this.state = {scrollPauser: false};
        this.pauseList = [];
    }

    scrollNeeded() {
        if (this.list.scrollHeight <= this.list.offsetHeight) return false;
        this.needsScroll = this.list.offsetHeight < this.list.scrollHeight;

        // Show the scroll pauser if we can scroll, but user is not towards the bottom
        if (this.scrolledUp) {
            this.needsScroll = false;
        }
        return this.needsScroll;
    }

    scrollToBottom() {
        this.list.scrollTo({left: 0, top: this.list.scrollHeight, behavior: "auto"});
        this.needsScroll = false;
        this.scrolledUp = false;
        this.lastScrollTop = 0;
        if (this.state.scrollPauser) {

            this.pauseList = [];
            this.setState({scrollPauser: false});
        }
    }

    // If either flag was previously disabled, but now re-enabled, scroll down
    filterUnchecked(prevState) {
        // If there is no previous state, no need to scroll
        if (!prevState.filters) return false;
        if (!prevState.filters.nonSubOnly && this.props.filters.nonSubOnly) {
            return true;
        }
        return !!(!prevState.filters.subOnly && this.props.filters.subOnly);
    }

    componentDidMount() {
        if (this.props.disableAutoScroll) return;
        this.list = document.getElementById(this.props.listId);
        this.list.addEventListener("scroll", function () {
            const st = this.list.scrollTop;
            if (st < this.lastScrollTop) {
                this.scrolledUp = true;
                if (!this.state.scrollPauser) {
                    this.pauseList = this.props.msgs;
                    this.setState({scrollPauser: true});
                }
            } else {
                this.lastScrollTop = 0;
            }
            this.lastScrollTop = st <= 0 ? 0 : st;
        }.bind(this), false);
        window.addEventListener("resize", function () {
            this.setState({scrollPauser: false});
            this.scrollToBottom();
        }.bind(this));
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.disableAutoScroll) return;
        if (this.scrollNeeded()) {
            this.scrollToBottom();
        }
    }

    showMessage(msg) {
        const chat = <Chat key={msg.key} time={msg.time} userCardUrl={msg.userCardUrl} messageId={msg.messageId}
                           listId={this.props.listId} issueClass={msg.issueClass}
                           badgeList={msg.badgeList} flags={msg.flags} firstTimeChatter={msg.firstTimeChatter}
                           msg={msg.msg} reply={msg.reply}
                           user={msg.user}/>;
        if (!this.props.filters) {
            return chat;
        }
        if (this.props.filters.subOnly && msg.flags.subscriber) {
            return chat;
        }
        if (this.props.filters.nonSubOnly && !msg.flags.subscriber) {
            return chat;
        }
    }

    getList() {
        let messages = this.props.msgs;
        if (this.state.scrollPauser) {
            messages = this.pauseList;
        }
        return <>
            {messages.map(msg => msg.event ?
                <Event key={msg.key} event={msg.event} msg={msg.msg}/> :
                this.showMessage(msg))}
        </>;
    }


    render() {
        return (
            <div className="list-wrapper">
                <small className="text-light list-title d-table mx-auto my-1">{this.props.title}</small>
                <Pauser className={this.state.scrollPauser ? '' : 'd-none'} pauseId={this.props.listId}
                        handler={this.scrollToBottom.bind(this)}/>
                <ul id={this.props.listId}
                    className={`list-group chat-list${this.props.className ? ` ${this.props.className}` : ''}`}>
                    {this.getList()}
                </ul>
            </div>
        );


    }
}

export default List;
