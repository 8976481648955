import ComfyJS from "comfy.js";
import './App.scss';
import Main from "./js/Main/Main";
import 'bootstrap/dist/css/bootstrap.min.css';
import React from "react";
import Uptime from "./js/Main/Streamer/Uptime";
import {Button, Modal, Spinner} from "react-bootstrap";
import 'react-autocomplete-input/dist/bundle.css';

let currentEmotes = new Map();
const EMOTE_URL = "https://static-cdn.jtvnw.net/emoticons/v2/$EMOTE_ID/default/dark/$EMOTE_SIZE";
const EMOTE_SIZE = [
    {
        size: "1x",
        urlSnippet: "1.0"
    },
    {
        size: "2x",
        urlSnippet: "2.0"
    },
    {
        size: "4x",
        urlSnippet: "3.0"
    }
];
let liveId = null;
let GLOBAL_EMOTES = {};
let GLOBAL_EMOTES_MAP = new Map();
let usersEmotes = new Map();
// 24 hour global emotes cache
const GLOBAL_ELAPSED_CACHE = 1000 * 60 * 60 * 24;
let GLOBAL_ZERO_WIDTHS = [];
let msgQueue = [];
let msgId = null;
let ZERO_WIDTH = new Map();
let BOTS = new Map();
let giftCounts = new Map();
let FOLLOWED = new Map();
const ANONYMOUS = "AnAnonymousGifter";
BOTS.set("Nightbot", true);
BOTS.set("StreamElements", true);
BOTS.set("Moobot", true);
BOTS.set("Deepbot", true);
BOTS.set("Wizebot", true);
BOTS.set("Ankhbot", true);
BOTS.set("Phantombot", true);
BOTS.set("Xanbot", true);
BOTS.set("Fossabot", true);
BOTS.set("FishingForEmotes", true);
BOTS.set("Supibot", true);
let MESSAGES = new Map();
const ISSUES = new Map();
ISSUES.set("Cross Ban", "tc-issue-crossban");
ISSUES.set("Ban", "tc-issue-ban");
ISSUES.set("Long Timeout", "tc-issue-long-timeout");
ISSUES.set("Timeout", "tc-issue-timeout");

if (GLOBAL_ELAPSED_CACHE === 0) {
    localStorage.clear();
}
let fetchingUser = false;

function isGlobalExpired() {
    return new Date().valueOf() - Number(localStorage.getItem("lastQueried")) >= GLOBAL_ELAPSED_CACHE;
}

async function getGlobalEmotes() {

    const lastQuery = localStorage.getItem("lastQueried");
    let emotes= localStorage.getItem("globalEmotes");
    if (emotes !== "undefined") {
        GLOBAL_EMOTES = JSON.parse(emotes);
    }
    let zeros = localStorage.getItem("globalZeroEmotes");
    if (zeros !== "undefined") {
        GLOBAL_ZERO_WIDTHS = JSON.parse(zeros);
    }
    if (!lastQuery || !GLOBAL_EMOTES || !GLOBAL_ZERO_WIDTHS || isGlobalExpired()) {
        // Call the external API to retrieve all emotes
        const emotes = await fetch("/api/emotes/global").then(response => {
            return response.json();
        }).catch(error => {
            return {error: "Not Found"};
        });
        GLOBAL_EMOTES = emotes.allEmotes;
        GLOBAL_ZERO_WIDTHS = emotes.zeroWidths;
        localStorage.setItem("globalEmotes", JSON.stringify(GLOBAL_EMOTES));
        localStorage.setItem("globalZeroEmotes", JSON.stringify(GLOBAL_ZERO_WIDTHS));
        localStorage.setItem("lastQueried", new Date().valueOf() + '');
    }
    Object.keys(GLOBAL_EMOTES).forEach((key) => GLOBAL_EMOTES_MAP.set(key, GLOBAL_EMOTES[key]));
}

async function getUsersEmotes() {
    const response = await fetch("/api/user/emotes").then(r => r.json()).catch(() => []);
    if (!response.emotes) {
        usersEmotes.clear();
        return;
    }
    Object.keys(response.emotes).forEach((key) => usersEmotes.set(key, response.emotes[key]));
    this.setTypeaheadEmotes();
}

async function getUser() {
    if (fetchingUser) return;
    fetchingUser = true;
    const user = await fetch("/api/user").then(r => r.json()).catch(() => {
        return {follows: [], name: '', img: ''}
    });
    FOLLOWED.clear();
    user.follows.forEach(follow => FOLLOWED.set(follow.toLowerCase(), true));
    fetchingUser = false;
    await getUsersEmotes.bind(this)();
    this.setState({user: user, fromFetched: true});
}


getGlobalEmotes();

class App extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            topChats: [],
            allChats: [],
            firstTimeChatters: [],
            spotlightUsers: new Map(),
            url: "",
            width: 0,
            height: 0,
            botsFlag: true,
            emoteOnlyFlag: true,
            commandFlagHandler: true,
            streamer: {displayName: "No Connection", avatar: ""},
            streamInfo: {startTime: ''},
            showNoUserModal: false,
            user: {follows: [], name: '', img: ''},
            emotes: [],
            connecting: false,
            footer: <Spinner animation="border" variant="primary"/>,
            reply: {},
            fromFetched: false
        };
        ComfyJS.onChat = (user, message, flags, self, extra) => this.appendMessage(user, message, flags, extra);
        ComfyJS.onCommand = (user, command, message, flags, extra) => {
            if (this.state.commandFlag) {
                this.appendMessage(user, `!${command} ${message}`, flags, extra);
            }
        }
        this.currentStreamer = "";
        this.invalidUser = "";
        let preloadStreamer = window.location.pathname;
        this.preloadChat = preloadStreamer !== "/";
        ComfyJS.onRaid = ((user, viewers, extra) => {
            let msg = `${user} is raiding with ${viewers} viewer${viewers > 1 ? 's' : ''}!`
            this.appendEvent('raid', msg, user);
        });
        ComfyJS.onSubMysteryGift = ((gifterUser, numbOfSubs, senderCount, subTierInfo, extra) => {
            const previousGiftCount = giftCounts.get(gifterUser) ?? 0;
            giftCounts.set(gifterUser, previousGiftCount + numbOfSubs);
            let msg = `${gifterUser === ANONYMOUS ? "Anonymous" : gifterUser} gifted ${numbOfSubs > 1 ? numbOfSubs : "a"} ${this.getSubTier(subTierInfo.plan)} sub${numbOfSubs > 1 ? 's' : ''}!`;
            this.appendEvent('sub', msg, gifterUser);
        });
        ComfyJS.onSubGift = (gifterUser, streakMonths, recipientUser, senderCount, subTierInfo, extra) => {

            const user = gifterUser;
            const previousGiftCount = giftCounts.get(user) ?? 0;
            if (previousGiftCount > 0) {
                giftCounts.set(user, previousGiftCount - 1);
            } else {
                let msg = `${gifterUser === ANONYMOUS ? "Anonymous" : gifterUser} gifted ${recipientUser} a ${this.getSubTier(subTierInfo.plan)} sub!`;
                this.appendEvent('sub', msg, user);
            }
        }
        ComfyJS.onCheer = (user, message, bits, flags, extra) => {
            let msg = `${user} cheered ${bits} bits:\n${message}`;
            this.appendEvent('bits', msg, user);
        }
        ComfyJS.onBan = (user) => {
            let msg = `${user} has been banned`;
            this.appendEvent('admin', msg, user);
        }
        ComfyJS.onTimeout = (user, duration) => {
            let msg = `${user} has been timed out for ${duration} seconds`;
            this.appendEvent('admin', msg, user);
        }
    }

    setReply(event) {
        let chatter = event.target.getAttribute("chatter");
        let messageId = event.target.getAttribute("msgid");
        let message = MESSAGES.get(chatter.toLowerCase()).get(messageId);
        this.setState({reply: {msg: message, messageId: messageId}})
    }

    clearReply() {
        this.setState({reply: {}});
    }

    getEmoteUrl(emote) {
        if (usersEmotes.has(emote)) return usersEmotes.get(emote);
        if (currentEmotes.has(emote)) return currentEmotes.get(emote);
        if (GLOBAL_EMOTES_MAP.has(emote)) return GLOBAL_EMOTES_MAP.get(emote);
    }


    handleNoUserClose() {
        this.setState({showNoUserModal: false});
    }

    resetZeroWidths() {
        ZERO_WIDTH.clear();
        GLOBAL_ZERO_WIDTHS.forEach(zero => ZERO_WIDTH.set(zero, true));
    }

    setConnecting(status) {
        this.setState({connecting: status});
    }

    setTypeaheadEmotes() {
        let type = [...(new Map([...GLOBAL_EMOTES_MAP, ...currentEmotes, ...usersEmotes])).keys()];
        this.setState({emotes: type});
        if (this.state.user.name) {
            this.setState({fromFetched: false});
        }
    }

    /**
     * Retrieve the channel emotes for chat (Twitch, BTTV, 7TTV, FFZ)
     */
    async getEmotes() {
        const streamer = this.currentStreamer;
        fetch(`/api/emotes/${streamer}`).then(r => r.json().then(emotes => {
            currentEmotes.clear();
            Object.keys(emotes.currentEmotes).forEach((key) => currentEmotes.set(key, emotes.currentEmotes[key]));
            this.setTypeaheadEmotes();
            this.resetZeroWidths();
            emotes.zeroWidth.forEach(emote => ZERO_WIDTH.set(emote[0], true));
        }));
    }

    async getStreamerData(streamer) {
        return await fetch(`/api/streamers/${streamer}`).then(r => r.json());
    }

    getSubTier(tierValue) {
        if (tierValue === '1000') {
            return 'Tier 1'
        }
        if (tierValue === '2000') {
            return 'Tier 2'
        }
        if (tierValue === '3000') {
            return 'Tier 3'
        }
    }

    buildEmoteObject(id, name) {
        // Add the current emote if it doesn't already exist
        if (!currentEmotes.has(name)) {
            currentEmotes.set(name, EMOTE_SIZE.map(url => `${EMOTE_URL.replace("$EMOTE_ID", id).replace("$EMOTE_SIZE", url.urlSnippet)} ${url.size}`));
        }

    }

    getTwitchEmotes(msg, emotes) {
        for (const key in emotes) {
            if (emotes.hasOwnProperty(key)) {
                const emote = emotes[key][0];
                const [start, end] = emote.split("-");
                const emoteName = msg.substring(+start, +end + 1);
                this.buildEmoteObject(key, emoteName);
            }
        }
    }

    async componentDidMount() {
        const user = getUser.bind(this);
        await user();
        if (this.preloadChat) {
            let preloadStreamer = window.location.pathname;
            this.joinStream(preloadStreamer.substring(1).toLowerCase());
        }
    }

    /**
     * Joins given streamer's chat
     * @param streamer string value of streamer
     */
    async joinStream(streamer) {
        if (this.state.connecting) return;
        // Only attempt to connect if given a new streamer
        if (streamer.toLowerCase() !== this.currentStreamer.toLowerCase()) {
            const streamerData = await this.getStreamerData(streamer);
            if (streamerData.invalid) {
                this.invalidUser = streamer;
                document.getElementById("streamer-input").value = streamer;
                this.setState({showNoUserModal: true});
                this.setConnecting(false);
                return;
            }
            this.setState({streamer: {displayName: "", isLoading: true, isLive: false}, streamInfo: {startTime: ''}});
            this.setState({streamer: streamerData, topChats: [], allChats: [], firstTimeChatters: []});
            if (streamerData.id > 0) {
                document.title = `${streamerData.displayName} Chat`;
            } else {
                document.title = "Top Chat";
            }

            // Disconnect from the previous chat if we have a connection already
            if (this.currentStreamer) {
                ComfyJS.Disconnect();
                msgQueue = [];
                clearTimeout(msgId);
                msgId = null;
            }
            if (liveId) {
                clearTimeout(liveId);
                liveId = null;
            }
            this.currentStreamer = streamer;
            MESSAGES.clear();
            this.clearReply();
            this.getLiveState();
            ComfyJS.Init(streamer);
            window.history.replaceState(null, null, `/${streamerData.displayName}`);
            await this.getEmotes();
            this.setConnecting(false);
        }
    }


    msToTime(s) {
        let ms = s % 1000;
        s = (s - ms) / 1000;
        let secs = s % 60;
        s = (s - secs) / 60;
        let mins = s % 60;
        let hrs = (s - mins) / 60;
        let time = {};
        time.hours = hrs;
        time.minutes = mins;
        time.seconds = secs;
        return time;
    }


    getLiveState() {
        try {
            fetch(`api/streamers/${this.currentStreamer}/live`).then(function (r) {
                r.json().then(function (response) {
                    this.setState({isLive: response.live});
                    this.setState({isLive: response.live});
                    this.setState({
                        streamInfo: {
                            title: response.title,
                            category: response.category,
                            viewers: response.viewers
                        }
                    });
                    if (!this.state.streamInfo.startTime && response.startTime !== -1) {
                        const time = this.msToTime(new Date() - new Date(response.startTime));
                        const timer = <Uptime startTime={time}/>;
                        this.setState({streamInfo: {...this.state.streamInfo, startTime: timer}});
                    }
                    liveId = setTimeout(this.getLiveState.bind(this), 20000)

                }.bind(this));
            }.bind(this));
        } catch {
            if (liveId) {
                clearTimeout(liveId);
            }
        }
    }

    /**
     * Event Handler for Enter Chat button click
     * @param streamer string value of streamer
     */
    streamerClickHandler(streamer) {
        // Break out if input is empty
        if (!streamer) {
            return;
        }
        this.joinStream(streamer);
    }

    /**
     * Get emote from string name
     * @param name string value of emote
     * @returns {boolean|*} false if not found, emote object otherwise
     */
    getEmote(name) {
        // Check if given emote string is a channel-specific emote instead
        if (currentEmotes.get(name)) {
            return currentEmotes.get(name);
        }
        // Check if given emote string is a global emote
        if (GLOBAL_EMOTES_MAP.has(name)) {
            return GLOBAL_EMOTES_MAP.get(name);
        }
        // Current string is not an emote
        return false;
    }


    /**
     * Parse through chatter's message to look for potential emotes in any
     * @param msg chatter's message string
     * @returns {*} array of plaintext and emote objects found in message
     */
    parseForEmotes(msg) {

        let lastEmoteIndex = -1;
        const updatedMsg = [];

        // Iterate through each word, returning an emote object if an emote if found, otherwise return the plaintext word
        msg.split(" ").forEach((str, index) => {
            const emote = this.getEmote(str)
            // If current word is an emote, convert it to an object
            if (emote) {
                // Check how the emote will be displayed
                const isZeroWidth = !!ZERO_WIDTH.get(str);
                // If it ISNT a zero width, assume it is a normal emote
                if (!isZeroWidth) {
                    updatedMsg.push({name: str, urls: emote.join(","), zeroWidth: []});
                    lastEmoteIndex = updatedMsg.length - 1;
                } else if (lastEmoteIndex >= 0){
                    updatedMsg[lastEmoteIndex].zeroWidth.push({name: str, urls: emote.join(",")});
                } else {
                    updatedMsg.push({name: str, urls: emote.join(","), zeroWidth: []});
                }
            } else {
                // Current word is plaintext, return it as-is
                updatedMsg.push(str);
            }
        });
        return updatedMsg;
    }

    /**
     * Check if the given user has non-english characters and display both names if found
     * @param extra Comfy.js object of extra flags for message
     * @returns {string|*} display name string
     */
    getUsername(extra) {
        if (extra.displayName.toLowerCase().trim() === extra.username.toLowerCase().trim()) {
            return extra.displayName;
        }
        return `${extra.displayName} (${extra.username})`;
    }

    rgbToHsl(r, g, b) {
        r /= 255;
        g /= 255;
        b /= 255;
        var max = Math.max(r, g, b), min = Math.min(r, g, b);
        var h, s, l = (max + min) / 2;

        if (max === min) {
            h = s = 0; // achromatic
        } else {
            var d = max - min;
            s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
            switch (max) {
                case r:
                    h = (g - b) / d + (g < b ? 6 : 0);
                    break;
                case g:
                    h = (b - r) / d + 2;
                    break;
                case b:
                    h = (r - g) / d + 4;
                    break;
                default:
                    break;
            }
            h /= 6;
        }

        return [h, s, l];
    }

    hslToRgb(h, s, l) {
        var r, g, b;

        if (s === 0) {
            r = g = b = l; // achromatic
        } else {
            function hue2rgb(p, q, t) {
                if (t < 0) t += 1;
                if (t > 1) t -= 1;
                if (t < 1 / 6) return p + (q - p) * 6 * t;
                if (t < 1 / 2) return q;
                if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;
                return p;
            }

            var q = l < 0.5 ? l * (1 + s) : l + s - l * s;
            var p = 2 * l - q;
            r = hue2rgb(p, q, h + 1 / 3);
            g = hue2rgb(p, q, h);
            b = hue2rgb(p, q, h - 1 / 3);
        }

        return [r * 255, g * 255, b * 255];
    }

    /**
     * Check if user color is "dark", setting it to a light color instead if it is
     * @param color rbg color string
     * @returns {string|*}
     */
    getColor(color) {
        if (!color) {
            return "#fefefe";
        }
        const c = color.substring(1);
        const rgb = parseInt(c, 16);
        const r = (rgb >> 16) & 0xff;
        const g = (rgb >> 8) & 0xff;
        const b = (rgb >> 0) & 0xff;
        let luma = r*0.299 + g*0.587 + b*0.114;

        if (luma < 75) {
            let hsl = this.rgbToHsl(r, g, b);
            const l = hsl[2];
            hsl[2] = l < .65 ? .65 : l * 1.5;
            return `rgb(${this.hslToRgb(...hsl).join(",")})`;
        } else {

            return color;
        }
    }

    async renderMessages() {
        const allChat = this.state.allChats;
        if (allChat.length > 300) {
            const oldMsgs = allChat.length + msgQueue.length - 300;
            allChat.splice(0, oldMsgs);
        }
        this.setState({allChats: [...allChat, ...msgQueue.splice(0, msgQueue.length)]});
        msgId = null;
    }

    addSpotlightUser(user) {
        let spotlightMap = this.state.spotlightUsers;
        if (!spotlightMap.has(user)) {
            spotlightMap.set(user, true);
            this.setState({spotlightUsers: spotlightMap});
        }
    }

    hasMention(message) {
        if (this.state.user.name) return message.toLowerCase().indexOf(this.state.user.name.toLowerCase()) >= 0;
        return false;
    }

    /**
     * Adds new message to TopChat if message is from elevated user
     * @param user
     * @param message
     * @param flags
     * @param extra
     */
    async appendMessage(user, message, flags, extra) {
        if (!this.state.botsFlag && BOTS.has(user)) return;
        this.getTwitchEmotes(message, extra.messageEmotes);
        let badges = this.getBadges(extra, flags, user);
        let newEntry;
        flags.mention = this.hasMention(message);
        if (!badges.length) {
            badges = this.getSecondaryBadges(extra, flags)
            newEntry = this.buildNewMessageObject(user, badges, message, extra, flags);
            newEntry.isEmoteOnly = newEntry.msg.every(chat => typeof chat === "object" || chat === "" || chat === " " || !chat);
            if (!this.state.emoteOnlyFlag && newEntry.isEmoteOnly) return;
            this.pushNewMessage(newEntry);
        } else {
            const top = this.state.topChats;
            badges = [...badges, ...this.getSecondaryBadges(extra, flags)];
            newEntry = this.buildNewMessageObject(user, badges, message, extra, flags);
            newEntry.isEmoteOnly = newEntry.msg.every(chat => typeof chat === "object" || chat === "" || chat === " " || !chat);
            if (!this.state.emoteOnlyFlag && newEntry.isEmoteOnly) return;
            if (top.length > 100) {
                top.shift();
            }
            this.setState({topChats: [...top, newEntry]});
            this.pushNewMessage(newEntry);
        }
        if (newEntry.firstTimeChatter) {
            this.setState({firstTimeChatters: [...this.state.firstTimeChatters, newEntry]});
        }
        let chatter = extra.username.toLowerCase();
        if (!MESSAGES.has(chatter)) {
            MESSAGES.set(chatter, new Map());
        }
        MESSAGES.get(chatter).set(extra.id, newEntry);
    }

    pushNewMessage(newEntry) {
        msgQueue.push(newEntry);
        if (!msgId) {
            msgId = setTimeout(this.renderMessages.bind(this), 150);
        }
    }

    getChattersMessages(chatter) {
       return MESSAGES.get(chatter.toLowerCase());
    }

    appendEvent(event, msg, user) {
        const allChat = this.state.topChats;
        const newEntry = {
            key: "event-" + event + "-" + user + "-" + new Date().valueOf(),
            event: event,
            msg: msg
        };
        if (event === "admin") {
            this.pushNewMessage(newEntry)
        } else {
            if (allChat.length > 100) {
                allChat.shift();
            }
            this.setState({topChats: [...allChat, newEntry]});
        }
    }

    emptyFTCLog() {
        this.setState({firstTimeChatters: []});
    }

    removeSpotlightUser(user) {
        let spotlights = this.state.spotlightUsers;
        spotlights.delete(user);
        this.setState({spotlightUsers: spotlights})
    }

    /**
     * Build user object with username and color
     */
    getUserObject(extra) {
        return {username: this.getUsername(extra), style: {color: this.getColor(extra.userColor)}}
    }

    getReply(msg, extra) {
        if (extra["reply-parent-display-name"]) {
            return {
                originalChatter: extra["reply-parent-display-name"],
                originalMessage: extra["reply-parent-msg-body"],
                reply: this.parseForEmotes(msg.substring(msg.indexOf(" ") + 1))
            };
        }
        return false;
    }


    /**
     * Create object used to display message to UI
     */
    buildNewMessageObject(user, badges, message, extra, flags) {
        return {
            key: extra.id,
            badgeList: badges,
            msg: this.parseForEmotes(message),
            firstTimeChatter: extra.userState["first-msg"],
            flags: {
                subscriber: flags.subscriber,
                mention: flags.mention
            },
            messageId: extra.id,
            reply: this.getReply(message, extra.userState),
            userCardUrl: `https://www.twitch.tv/popout/${this.currentStreamer}/viewercard/${user}`,
            time: new Date().toLocaleString('en-US', {hour: 'numeric', minute: 'numeric', second: 'numeric'}),
            user: this.getUserObject(extra)
        };
    }

    /**
     * Get chat badges, if any
     */
    getBadges(extra, flags) {
        const badges = [];
        if (extra.displayName.toLowerCase() === 'imbedhead') {
            badges.push({key: "topchatter" + new Date().valueOf(), type: "topchatter", name: "Top Chat Dev"});
        }
        if ((extra.userBadges && extra.userBadges.staff)) {
            badges.push({key: "staff" + new Date().valueOf(), type: "staff", name: "Staff"});
        }
        if (flags.broadcaster) {
            badges.push({key: "broadcaster" + new Date().valueOf(), type: "broadcaster", name: "Broadcaster"});
        }
        if (flags.founder) {
            badges.push({
                key: "founder" + new Date().valueOf(),
                type: "founder",
                name: "Founder",
                badgeName: this.getSubLength(Number(extra.userState["badge-info"].founder), extra.userState, "founder")
            });
        }
        if (flags.mod) {
            badges.push({key: "mod" + new Date().valueOf(), type: "mod", name: "Moderator"});
        }
        if (flags.vip) {
            badges.push({key: "vip" + new Date().valueOf(), type: "vip", name: "VIP"});
        }
        if (FOLLOWED.has(extra.displayName.toLowerCase())) {
            badges.push({key: "followed" + new Date().valueOf(), type: "followed", name: "Followed"});
        }
        if (!badges.length && this.state.spotlightUsers.has(extra.displayName.toLowerCase())) {
            badges.push({key: "spotlight" + new Date().valueOf(), type: "spotlight", name: "Spotlight"});
        }
        return badges;
    }

    getSubBadge(monthSub) {
        const badges = this.state.streamer.badges;
        return badges.find(badge => badge.id === monthSub);
    }

    getTier(monthSub) {
        if (monthSub % 2000 < 1000) {
            return "Tier 2";
        }
        if (monthSub % 3000 < 1000) {
            return "Tier 3";
        }
        return '';
    }

    botFlagHandler() {
        this.setState({botsFlag: !this.state.botsFlag});
    }

    emoteOnlyFlagHandler() {
        this.setState({emoteOnlyFlag: !this.state.emoteOnlyFlag});
    }

    commandFlagHandler() {
        this.setState({commandFlag: !this.state.commandFlag});
    }

    getSubLength(monthSub, userState, subSelector) {
        if (monthSub === 0 || monthSub === 2000 || monthSub === 3000) {
            return `1-Month Subscriber${monthSub >= 2000 ? "(" + this.getTier(monthSub) + ")" : ''}`;
        }
        const months = Number(userState["badge-info"][subSelector]);
        if (months < 12) {
            return `${monthSub % 1000}-Month Subscriber${monthSub === months ? '' : " (" + (monthSub >= 2000 ? this.getTier(monthSub) + ", " : '') + months + " Months)"}`;
        }
        const year = Math.floor(months / 12);
        return `${year}${months % 12 >= 6 ? ".5" : ''} Year Subscriber (${(monthSub >= 2000 ? this.getTier(monthSub) + ", " : '') + months} Months)`;
    }

    /**
     * Get chat badges, if any
     */
    getSecondaryBadges(extra, flags) {
        const badges = [];
        if (flags.subscriber && !flags.founder) {
            const monthSub = extra.userState.badges.subscriber;
            if (this.state.streamer.badges.length && monthSub) {
                try {
                    const subBadge = this.getSubBadge(monthSub);
                    badges.push({
                        key: "subscriber" + new Date().valueOf(),
                        isSubBadge: true,
                        badgeName: this.getSubLength(Number(monthSub), extra.userState, "subscriber"),
                        badge: {backgroundImage: `-webkit-image-set(url(${subBadge.image_url_2x}) 2x, url(${subBadge.image_url_4x}) 4x)`},
                        tooltipBadge: {backgroundImage: `url('${subBadge.image_url_4x}')`}
                    });
                } catch {
                    badges.push({key: "subscriber" + new Date().valueOf(), type: "subscriber", name: "Subscriber"});
                }
            } else {
                badges.push({key: "subscriber" + new Date().valueOf(), type: "subscriber", name: "Subscriber"});
            }
        }
        if (flags.mention) {
            badges.push({key: "mention" + new Date().valueOf(), type: "mention", name: "Mention"});
        }
        return badges;
    }

    render() {
        return (
            <div className="App text-light">
                <Modal scrollable={true} dialogClassName="tc-modal tc-no-user-found" show={this.state.showNoUserModal}
                       onHide={this.handleNoUserClose.bind(this)}>
                    <Modal.Header closeButton>
                        <Modal.Title>No user found</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        The user {this.invalidUser} was not found
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="outline-secondary" onClick={this.handleNoUserClose.bind(this)}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
                <Main msgs={this.state.topChats} allChats={this.state.allChats}
                      spotlightHandler={this.addSpotlightUser.bind(this)}
                      spotlights={this.state.spotlightUsers}
                      removeSpotlight={this.removeSpotlightUser.bind(this)}
                      botsHandler={this.botFlagHandler.bind(this)}
                      emoteOnlyHandler={this.emoteOnlyFlagHandler.bind(this)}
                      commandHandler={this.commandFlagHandler.bind(this)}
                      firstTimeChatters={this.state.firstTimeChatters}
                      clearLog={this.emptyFTCLog.bind(this)}
                      height={this.state.height}
                      loaded={this.preloadChat}
                      getMessages={this.getChattersMessages.bind(this)}
                      user={this.state.user}
                      footer={this.state.footer}
                      emotes={this.state.emotes}
                      getEmoteUrl={this.getEmoteUrl}
                      fromFetched={this.state.fromFetched}
                      reply={this.state.reply}
                      replyHandler={this.setReply.bind(this)}
                      clearReply={this.clearReply.bind(this)}
                      submissionHandler={this.setConnecting.bind(this)}
                      width={this.state.width} isLive={this.state.isLive} streamInfo={this.state.streamInfo}
                      streamerClickHandler={this.streamerClickHandler.bind(this)} streamer={this.state.streamer}/>
            </div>
        );
    }
}

export default App;
