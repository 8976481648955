import React from "react";
import './Header.scss';
import Streamer from "./Streamer/Streamer";
import Info from "./Streamer/Info";
import StreamInput from "./StreamInput";

class Header extends React.Component {
    constructor(props) {
        super(props);
        this.state = {streamerInput : ""}
    }



    render() {
        return (
            <div className="d-inline-flex" id="header">
                <div className="d-inline-flex" id="user-input">
                    <StreamInput onChange={this.props.changeHandler} onKeyDown={this.props.mainPressHandler}
                                 user={this.props.user} onClick={this.props.mainClickHandler}/>
                </div>
                <div id="stream-info">
                    <Info isLive={this.props.isLive} stream={this.props.stream}/>
                </div>
                <Streamer data={this.props.streamer} isLive={this.props.isLive} stream={this.props.stream}/>
            </div>
        );
    }
}
export default Header;
