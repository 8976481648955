import React from "react";

class Info extends React.Component {
    render() {
        return (
            <div>
                <div>
                    <div className="stream-title">
                        {this.props.stream.title}
                    </div>
                    <div className="d-inline-flex">
                        <div className="stream-category"><i className={'tc-live-info tc-category-icon mx-2 ' + (this.props.stream.category ? '' : 'd-none')}></i> {this.props.stream.category}</div>
                        <div className="d-inline-flex"><i className={'tc-live-info tc-viewers-icon mx-2 ' + (this.props.stream.viewers ? '' : 'd-none')}></i> {this.props.stream.viewers}</div>
                        <div className="d-inline-flex"><i className={'tc-live-info tc-uptime-icon mx-2 ' + (this.props.stream.startTime ? '' : 'd-none')}></i> {this.props.stream.startTime}</div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Info;
