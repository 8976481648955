import React from "react";
import {OverlayTrigger, Tooltip} from "react-bootstrap";
import './Emote.scss';

class Emote extends React.PureComponent {

    /**
     * Get the largest emote url to display in tooltip
     * @param url
     * @returns {string}
     */
    getLargeEmoteUrl(url) {
        return url.substring(url.lastIndexOf(",") + 1, url.lastIndexOf(" "))
    }



    getClass() {
        let className = 'mx-1';
        if (this.props.emote.zeroWidth.length) {
            className = 'tc-zero-width-emote-wrapper';
        }
        return className;
    }


    render() {
        return (
            <span className={this.getClass()}>
                <OverlayTrigger
                    key={`top-${new Date().valueOf()}`}
                    placement="top"
                    overlay={
                        <Tooltip id={`tooltip-${new Date().valueOf()}`}>
                            <div className="emote-overlay-wrapper">
                                <img
                                src={this.getLargeEmoteUrl(this.props.emote.urls)}
                                alt={this.props.emote.name}/>
                                <div>{this.props.emote.name}
                            </div>
                                {this.props.emote.zeroWidth.map(zero => <>{zero.name} - <img className="tc-emote" srcSet={zero.urls} alt={zero.name}/></>)}

                            </div>
                        </Tooltip>
                    }>
                        <img className="tc-emote" srcSet={this.props.emote.urls} alt={this.props.emote.name}/>

                    </OverlayTrigger>

                {this.props.emote.zeroWidth.map(zero => <img className="tc-zero-width-emote" srcSet={zero.urls} alt={zero.name}/>)}
            </span>
        );
    }
}

export default Emote;
