import React from "react";
import Badge from "./Badge";
import SubBadge from "./Messages/SubBadge";

class BadgeList extends React.PureComponent {
    constructor(props) {
        super(props);
        this.badges = this.props.badges;
    }

    render() {
        return (
            <span>
                {this.badges.map(badge => badge.isSubBadge ?
                    <SubBadge key={badge} badgeName={badge.badgeName} badge={badge.badge}
                              tooltipBadge={badge.tooltipBadge}/> : <Badge key={badge.key} badgeName={
                        badge.badgeName ? badge.name + " " + badge.badgeName : badge.name
                    } className={badge.type}/>)}
            </span>
        );
    }
}

export default BadgeList;
