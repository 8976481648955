import React from "react";
import {Button} from "react-bootstrap";
import "./Pauser.scss";

class Pauser extends React.PureComponent {

    render() {
        return (
            <div className={this.props.className} onClick={this.props.handler}>
                <Button key={`${this.props.pauseId}-${new Date()}`} size={"sm"} className="mx-auto d-table tc-scroll-pauser" variant={"secondary"}>Auto-scroll
                    Paused, Click to Re-enable</Button>
            </div>
        );

    }
}

export default Pauser;
