import React from "react";
import TextInput from "../../autocomplete";
import {Spinner} from "react-bootstrap";
import Chat from "../Chat/Chat";

class Footer extends React.Component {
    constructor(props) {
        super(props);
        this.url = window.location.origin;
    }
    componentDidMount() {

        document.getElementById("tc-footer").addEventListener("click", (e) => {
            if (e.target.id === "reply-close-btn") {
                this.props.clearReply();
            }
        });
    }

    keyPress(event) {
        if (event.key.toLowerCase() === "enter" && !event.shiftKey) {
            event.preventDefault();
            let value = event.target.value;
            if (!value) {
                return;
            }
            event.target.value = "";
            event.target.innerHTML = "";
            fetch("/api/user/msg", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    msg: value.trim(),
                    channel: this.props.currentStreamer.displayName,
                    replyId: this.props.reply.messageId
                })
            });
            this.props.clearReply();
        } else if (event.key.toLowerCase() === "tab") {
            event.preventDefault();
        }
    }


    showChatBox() {
        if (this.props.currentStreamer.id) {

            return <TextInput Component="textarea" id={"tc-messenger"} maxLength={500} matchAny={true} expandUp={true}
                              onKeyDown={this.keyPress.bind(this)}
                              placeholder={"Send a message"} trigger={[":"]} delayMenu={false} minChars={2}
                              options={this.props.typeaheadEmotes}
                              renderOptionsList={(markUp, plaintext) => {
                                  return <><img alt={plaintext} srcSet={this.props.getEmoteUrl(plaintext)}/> {markUp}</>
                              }}/>;
        }
    }

    showReplyBox() {
        if (this.props.reply.msg) {
            const msg = this.props.reply.msg;
            return <div id="tc-replying-to">
                <div><span id="reply-title">Replying to {msg.user.username}:</span> <span id="reply-close-btn">✖</span>
                </div>
                <div className="chat-list">
                    <Chat key={msg.key} time={msg.time} userCardUrl={msg.userCardUrl} messageId={msg.messageId}
                          listId={"reply-to"}
                          badgeList={msg.badgeList} flags={msg.flags} firstTimeChatter={msg.firstTimeChatter}
                          msg={msg.msg} reply={msg.reply}
                          user={msg.user}/>
                </div>
            </div>
        }
        return "";
    }

    setPath() {
        localStorage.setItem("currentPath", window.location.pathname);
    }

    showUI() {
        if (!this.props.user.name && this.props.fromFetched) {
            return <a onClick={this.setPath} className="tc-button-wrapper"
                      href={`https://id.twitch.tv/oauth2/authorize?response_type=code&client_id=18e8e1bsfzvihtr9060w09p3j1ojtb&redirect_uri=${this.url}/twitch-oauth/redirect&scope=chat%3Aread+chat%3Aedit+channel%3Amoderate+user%3Aread%3Afollows+channel%3Aread%3Aredemptions+user%3Aread%3Aemail`}>
                <button className="btn btn-twitch btn-small">Link to Twitch</button>
            </a>;
        } else if (this.props.user.name) {
            return <>
                <div className="d-inline-flex" id="tc-user-wrapper"><img id="tc-user-img" alt={this.props.user.name}
                                                    src={this.props.user.img}/>
                    <div id="tc-user-name">{this.props.user.name}</div>
                </div>
                {this.showReplyBox()}
                {this.showChatBox()}
            </>;
        } else {
            return <Spinner animation="border" variant="primary"/>;
        }
    }


    render() {
        return (
            <div id="tc-footer">
                {this.showUI()}
            </div>
        );
    }
}

export default Footer;
