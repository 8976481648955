import React from "react";
import "./UserCard.scss";
import {Button} from "react-bootstrap";
import List from "./List.js";

class UserCard extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {msgs: [], expanded: false};
        this.chatter = this.props.chatter.indexOf("(") > 0 ? this.getDisplayName() : this.props.chatter;
    }

    getDisplayName() {
        let chatter = this.props.chatter;
        return chatter.substring(chatter.indexOf("(") + 1, chatter.length - 1);
    }

    addSpotlight() {
        this.props.spotlightHandler(this.chatter.toLowerCase());
    }

    showMessages() {
        if (this.state.expanded) {
            this.setState({expanded: false, msgs: []});
            return;
        }
        this.setState({expanded: true});
        const messages = this.props.messageHandler(this.chatter);
        if (messages) {
            this.setState({msgs: Array.from(messages.values())});
        }
    }

    render() {
        return (
            <div className="tc-user-card">
                <div className="tc-user-card-header">
                    <a href={`https://www.twitch.tv/${this.props.chatter}`} target="_blank">{this.props.chatter}</a>
                    <span style={{float: "right"}} onClick={this.props.closeHandler}>✖</span>
                </div>
                <div className="tc-user-card-body">
                    <Button className="btn-spotlight" onClick={this.addSpotlight.bind(this)} variant="outline-primary" title="Spotlight">&#128269;</Button>
                    <a href={this.props.url} target="_blank"><Button className="btn-twitch" title="Twitch User Card"><span/></Button></a>
                </div>
                <div className={`tc-user-card-msgs ${this.state.expanded ? 'expanded' : ''}`}><div className="tc-user-card-msgs-header" onClick={this.showMessages.bind(this)}>Chat messages this
                    session</div>
                    <List title=" " disableAutoScroll={true} listId="user-card-messages" msgs={this.state.msgs}/>
                </div>
            </div>
        );
    }
}

export default UserCard;
