import React from "react";
import './Event.scss';

class Event extends React.PureComponent {
    constructor(props) {
        super(props);
        this.index = this.props.key;
        this.event = props.event;
        this.msg = props.msg;
    }

    render() {
        return (
            <div className={`tc-event tc-event-type-${this.event}`} key={this.index}>
                <span>{this.msg}</span>
            </div>
        );
    }
}

export default Event;
